<template>
  <div class="detail">
    <loader v-if="loading" />
    <div v-else class="container">
      <div class="wrapper-detail">
        <div v-if="!article.is_announcement"></div>
        <div v-else>
          <div class="title-announcement">
            {{ article.title }}
          </div>
        </div>
        <announce-detail :data="article" v-if="article.is_announcement" />
        <news-details :data="article" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import AnnounceDetail from "@/components/AnnouncementDetail/AnnounceDetail.vue";
import NewsDetails from "@/components/AnnouncementDetail/NewsDetails.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ref } from "@vue/reactivity";
import Loader from "../components/loader.vue";
import { watch } from "vue";
export default {
  components: { AnnounceDetail, NewsDetails, Loader },
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const getData = () => {
      loading.value = true;
      store.dispatch("articles/getArticleId", {
        cb: () => {
          loading.value = false;
        },
        id: route.params.slug
      });
    };
    watch(
      () => route.params.slug,
      () => getData(),
      { immediate: true }
    );
    return {
      loading,
      article: computed(() => store.getters["articles/getArticleID"]),
    };
  },
};
</script>

<style lang="scss" scoped>
.detail {
  overflow: hidden;
}
strong,
.strong {
  font-weight: 600;
  a {
    color: #0238f6;
  }
}
p {
  margin-bottom: 2rem;
}
.title-announcement {
  font-size: 2em;
  font-weight: 600;
  line-height: 1.4;
}
.spacer-announcement {
  width: 100%;
  height: 5px;
  margin: 1.85em 0;
  background: linear-gradient(90deg, #9400d3 0%, #6f65eb 0.01%, #4b0082 100%);
}
.wrapper-detail {
  font-size: 16px;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding-top: 2.75rem;
  .img {
    position: fixed;
    top: 200px;
    right: 2vw;
    width: 35vw;
    z-index: -1;
    img {
      background: transparent;
    }
  }
  .title {
    width: 60%;
    margin-bottom: 1rem;
    background: linear-gradient(90deg, #9400d3 0%, #6f65eb 0.01%, #4b0082 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .brief {
    font-weight: 600;
    line-height: 1.5;
    width: 60%;
  }
  .spacer {
    width: 60%;
    height: 5px;
    margin: 1.85em 0;
    background: linear-gradient(90deg, #9400d3 0%, #6f65eb 0.01%, #4b0082 100%);
  }
  .title {
    font-size: 2em;
    font-weight: 600;
    line-height: 1.4;
  }
}
@media screen and (max-width: 1200px) {
  .wrapper-detail {
    font-size: 14px;
    .img {
      display: none;
    }
    .title {
      width: 100%;
    }
    .spacer {
      width: 100%;
    }
    .brief {
      font-weight: 600;
      line-height: 1.5;
      width: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .wrapper-detail {
    font-size: 12px;
    padding-top: 1.25rem;
  }
}
</style>
