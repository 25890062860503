<template>
  <div>
    <div class="announce-content">
      <div class="about-exem">
        <ul class="list">
          <li class="list__item">
            <strong>{{ $t("announcePage.data") }}</strong> &nbsp;{{
              getFullDate(data?.exam_date, false)
            }}
          </li>
          <li class="list__item">
            <strong>{{ $t("announcePage.members") }} </strong>&nbsp;
            <span v-if="data?.is_korean"> {{ $t("announcePage.korean") }}</span>
            <span v-else> {{ $t("announcePage.all") }}</span>
          </li>
          <li class="list__item">
            <strong>{{ $t("announcePage.clasification") }}</strong>
          </li>
        </ul>
        <div v-if="data.topik_1 || data.topik_2" class="table">
          <div class="table__header">
            <div class="table__header-item">
              {{ $t("announcePage.table.level") }}
            </div>
            <div class="table__header-item">
              {{ $t("announcePage.table.rating") }}
            </div>
            <div class="table__header-item">
              {{ $t("announcePage.table.criterion") }}
            </div>
          </div>
          <div class="table__body">
            <div
              class="table__body-container"
              v-for="(item, index) in topik"
              :key="index"
              v-show="index == 0 ? data.topik_1 : data.topik_2"
            >
              <div class="table__body-item">{{ item.topik }}</div>
              <div class="table__body-item">{{ item.level }}</div>
              <div class="table__body-item">
                <ul>
                  <li>
                    {{ item.list }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data?.topik_1" class="mobile-info purple-bg">
          <div class="title">{{ $t("announcePage.table.level") }}</div>
          <div class="subtitle">{{ topik[0].topik }}</div>
          <div class="title">{{ $t("announcePage.table.rating") }}</div>
          <div class="subtitle">{{ topik[0].level }}</div>
          <div class="title">{{ $t("announcePage.table.criterion") }}</div>
          <div class="subtitle">{{ topik[0].list }}</div>
        </div>
        <div v-if="data?.topik_2" class="mobile-info green-bg">
          <div class="title">{{ $t("announcePage.table.level") }}</div>
          <div class="subtitle">{{ topik[1].topik }}</div>
          <div class="title">{{ $t("announcePage.table.rating") }}</div>
          <div class="subtitle">{{ topik[1].level }}</div>
          <div class="title">{{ $t("announcePage.table.criterion") }}</div>
          <div class="subtitle">{{ topik[1].list }}</div>
        </div>
      </div>
      <div>
        <p class="strong">
          {{ $t("announcePage.sendRegistr.sendLink") }}
          (<router-link :to="$i18nRoute({ name: 'Register' })">{{
            $t("announcePage.link")
          }}</router-link
          >) <br />{{ $t("announcePage.sendRegistr.register") }}
        </p>
        <p class="strong">
          {{ $t("announcePage.sendRegistr.auth") }}(<router-link
            :to="$i18nRoute({ name: 'Auth' })"
            >{{ $t("announcePage.link") }}</router-link
          >)
        </p>
      </div>
      <div class="fill-application">
        <div class="text">
          <div v-if="data?.start_date_ko">
            <p class="strong">
              {{ $t("announcePage.sendOnlineRegister.title") }}
            </p>
            <p>
              {{ $t("announcePage.sendOnlineRegister.iskorean") }}

              {{ getFullDate(data?.start_date_ko, false) }} -
              {{ getFullDate(data?.end_date_ko, false) }}
            </p>
          </div>
          <div class="prompt-mobile">
            {{ $t("announcePage.sendOnlineRegister.noSend") }}
          </div>
          <p class="text-bottom">
            {{ $t("announcePage.sendOnlineRegister.all") }}
            {{ getFullDate(data?.start_date, false) }} -
            {{ getFullDate(data?.end_date, false) }}
          </p>
        </div>
        <div v-if="data?.start_date_ko" class="prompt">
          {{ $t("announcePage.sendOnlineRegister.noSend") }}
          <svg
            class="triangle"
            width="1.13rem"
            height="1.81rem"
            viewBox="0 0 18 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-7.21238e-07 14.5L17.25 0.21058L17.25 28.7894L-7.21238e-07 14.5Z"
              fill="var(--primary-blue)"
              fill-opacity="0.5"
            />
          </svg>
        </div>
      </div>
      <div class="fill-application bottom">
        <div class="text">
          <p>
            {{ $t("announcePage.sendOnlineRegister.getNumber") }}
            <!-- с 10 января 2022 года ( пн) по 21 января 2022 года (пт) -->
            {{ getFullDate(data.id_start_date, false) }} -
            {{ getFullDate(data.id_end_date, false) }}
          </p>
        </div>
        <div class="prompt-mobile">
          {{ $t("announcePage.sendOnlineRegister.order") }}
        </div>
        <div class="prompt">
          {{ $t("announcePage.sendOnlineRegister.order") }}
          <svg
            class="triangle"
            width="18"
            height="29"
            viewBox="0 0 18 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-7.21238e-07 14.5L17.25 0.21058L17.25 28.7894L-7.21238e-07 14.5Z"
              fill="var(--primary-blue)"
              fill-opacity="0.5"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="region-table">
      <span class="notification">
        {{ $t("announcePage.DinamicTable.title") }}
      </span>
      <div class="table-container">
        <div class="table-top">
          <div class="table-top__title">
            <span> {{ $t("announcePage.DinamicTable.region") }} </span>
          </div>
          <div class="table-top__title">
            <span> {{ $t("announcePage.DinamicTable.place") }} </span>
          </div>
          <div class="table-top__title">
            <div>
              <span> {{ $t("announcePage.DinamicTable.countPeople") }} </span>
            </div>
            <div class="table-top__subtitle">
              <div>{{ $t("announcePage.korean") }}</div>
              <div>{{ $t("announcePage.all") }}</div>
            </div>
          </div>
        </div>
        <div class="table-bottom" v-for="item in data.regions" :key="item.id">
          <div class="table-bottom__title">
            <span> {{ item.region.name }}</span>
          </div>
          <div class="table-bottom__title">
            <span> {{ item.address }} </span>
          </div>
          <div class="table-bottom__title">
            <div class="table-bottom__text">
              <div>
                TOPIK I- &nbsp;<strong>{{ item.korean_topik1 }}</strong>
              </div>
              <div>
                TOPIK II- &nbsp;<strong>{{ item.korean_topik2 }}</strong>
              </div>
            </div>
            <div class="table-bottom__text">
              <div>
                TOPIK I- &nbsp;<strong>{{ item.topik1 }}</strong>
              </div>
              <div>
                TOPIK II- &nbsp;<strong>{{ item.topik2 }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-table">
      <div
        class="mobile-table__item"
        v-for="item in data.regions"
        :key="item.id"
      >
        <div class="descr">
          <h5 class="title">{{ $t("announcePage.DinamicTable.region") }}</h5>
          <div class="sub-title">{{ item.region.name }}</div>
        </div>
        <div class="descr">
          <h5 class="title">{{ $t("announcePage.DinamicTable.place") }}</h5>
          <div class="sub-title">{{ item.address }}</div>
        </div>
        <div class="descr">
          <h5 class="title">
            {{ $t("announcePage.DinamicTable.countPeople") }}
          </h5>
          <div class="flex gap">
            <div class="flex-1">
              <div class="category">{{ $t("announcePage.korean") }}</div>
              <div class="flex topik">
                <div class="flex-1">
                  TOPIK I - <strong>{{ item.korean_topik1 }}</strong>
                </div>
                <div class="flex-1">
                  TOPIK II - <strong>{{ item.korean_topik2 }}</strong>
                </div>
              </div>
            </div>
            <div class="flex-1">
              <div class="category">{{ $t("announcePage.all") }}</div>
              <div class="flex topik">
                <div class="flex-1">
                  TOPIK I - <strong>{{ item.topik1 }}</strong>
                </div>
                <div class="flex-1">
                  TOPIK II - <strong>{{ item.topik2 }}</strong>
                </div>
              </div>
            </div>
            <!-- {{ $t("announcePage.all") }}
            <div class="flex-1"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="region-subtitle">
      <!-- <div class="left">
        <p>
          {{ $t("announcePage.time.left") }}
          <span class="purple-strong"> 20.12.2022 ( Пн) - 27. 12. 2022</span>
        </p>
      </div> -->
      <div class="right">
        <p>{{ $t("announcePage.time.right") }}</p>
        <p v-for="item in data.cities" :key="item.id">
          <span class="strong">{{ item.city.name }} :</span
          ><span class="purple-strong">
            &nbsp;{{ getFullDate(item.start_date, false) }} -
            {{ getFullDate(item.end_date, false) }}</span
          >
        </p>
      </div>
    </div>

    <div class="online-instruction mt-3">
      <h3 class="strong">{{ $t("announcePage.onlineApplication.title") }}</h3>
      <p class="strong">
        {{ $t("announcePage.onlineApplication.register") }}
        <a
          class="small-link"
          href="https://topiktashkec.uz/"
          target="_blank"
          rel="noopener noreferrer"
          >https://topiktashkec.uz/</a
        >
      </p>
      <p class="border-text">
        {{ $t("announcePage.onlineApplication.data") }}
        <svg
          class="triangle"
          width="29"
          height="18"
          viewBox="0 0 29 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 0L28.7894 17.25L0.210581 17.25L14.5 0Z"
            fill="var(--primary-blue)"
            fill-opacity="0.5"
          />
        </svg>
      </p>
      <p>
        {{ $t("announcePage.onlineApplication.descr.first") }}
      </p>
      <p>
        {{ $t("announcePage.onlineApplication.descr.second") }}
      </p>
      <p class="strong">
        {{ $t("announcePage.onlineApplication.descr.third") }}
      </p>
      <p>
        {{ $t("announcePage.onlineApplication.descr.fourth") }}
      </p>
      <h3 class="strong">
        {{ $t("announcePage.onlineApplication.descr.number") }}
      </h3>
      <p>
        {{ $t("announcePage.onlineApplication.descr.fivth") }}
      </p>
      <p>
        <!-- <span class="strong"> Необходимые документы : </span>
        анкета, копия паспорта ( свидетельсво о рождении ID карта ) -->
        {{ $t("announcePage.onlineApplication.descr.sixth") }}
      </p>
      <p>{{ $t("announcePage.onlineApplication.descr.pay") }}</p>
      <p>
        {{ $t("announcePage.onlineApplication.descr.seventh") }}
      </p>
      <p>{{ $t("announcePage.onlineApplication.descr.eight") }}</p>
      <h4 class="strong">
        {{ $t("announcePage.onlineApplication.descr.ninth") }}
      </h4>
      <p>
        {{ $t("announcePage.onlineApplication.descr.tenth") }}
      </p>
      <p>{{ $t("announcePage.onlineApplication.descr.eleventh") }}</p>
      <div class="data-description" v-html="data.description"></div>
      <h4 class="center">
        {{ $t("announcePage.onlineApplication.descr.korean") }}
      </h4>
      <h3 class="slide-title primary-title">{{ $t("announcePage.other") }}</h3>
      <AnnounceSlider />
    </div>
  </div>
</template>

<script setup>
import { useLanguage } from "@/utils/language";
import { useFormatter } from "@/utils/formatter";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import AnnounceSlider from "@/components/AnnounceSlider.vue";

const { getFullDate } = useFormatter();

const { i18n } = useLanguage();
const locale = i18n.global.locale.value;
const topik = i18n.global.messages.value[locale].announcePage.topik;
const store = useStore();

const data = computed(() => store.getters["articles/getArticleID"]);
</script>

<style lang="scss" scoped>
.data-description {
  line-height: 1.5;
  :deep(p) {
    margin-bottom: 1rem;
  }
}
.mobile-table {
  display: none;
}
.prompt-mobile {
  display: none;
}
strong,
.strong {
  font-weight: 600;
  a {
    color: var(--primary-blue);
  }
}
.small-link {
  font-size: 0.685em;
}
h3 {
  font-size: 1.185em;
  margin-bottom: 2rem;
}
h4 {
  margin: 2em 0;
}
.mt-3 {
  margin-top: 3rem;
}
.center {
  text-align: center;
}
.purple-strong {
  color: var(--primary-blue);
  font-weight: 600;
}
p {
  margin-bottom: 2rem;
}
.announce-content {
  font-size: 1.375em;
  .about-exem {
    .list {
      margin-bottom: 2rem;
      // display: flex;
      &__item {
        line-height: 1.8;
      }
    }
  }
  .table {
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    &__header {
      background-color: #f1f1f1;
      padding: 1em 1.1em;
      display: flex;
      font-weight: 600;
      &-item {
        flex: 1;
        &:nth-child(2) {
          flex: 1.5;
        }
        &:nth-child(3) {
          flex: 3;
        }
      }
    }
    &__body {
      &-container {
        display: flex;
        padding: 1em 1.1em;
        border-bottom: 2px solid #000;
        &:last-child {
          border: none;
        }
      }

      &-item {
        ul li:before {
          content: "-";
        }
        flex: 1;
        &:nth-child(2) {
          flex: 1.5;
        }
        &:nth-child(3) {
          flex: 3;
        }
      }
    }
  }
  .fill-application {
    width: 100;
    font-weight: 500;
    display: flex;
    p {
      margin-bottom: 1.5rem;
    }
    .text {
      flex: 0 0 53%;
      &-bottom {
        margin-top: 4rem;
        width: 115%;
      }
    }
    .prompt {
      margin-top: 2rem;
      margin-left: auto;
      position: relative;
      flex: 0 0 43%;
      font-size: 0.7em;
      height: min-content;
      color: #fff;
      padding: 1em;
      line-height: 1.5;
      background: var(--primary-blue);
      backdrop-filter: blur(13.6225px);

      border-radius: 10.2169px;
      .triangle {
        position: absolute;
        left: -1em;
        top: 50%;
        transform: translateY(-100%);
      }
    }
    &.bottom {
      .prompt {
        flex: 0 0 40%;
        margin-top: 0;
      }
    }
  }
}

.region-table {
  --first-column: 1;
  --second-column: 1.4;
  --third-column: 2;
  font-size: 1.375em;
  margin-top: 1rem;
  .notification {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 2;
    color: #a1a1a1;
  }
  .table-container {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
  }
  .table-top {
    display: flex;
    background-color: #f1f1f1;
    &__title {
      border-right: 1px solid #000;
      flex: var(--first-column);
      font-weight: 600;
      display: flex;
      align-items: center;
      &:last-child {
        border-right: none;
      }
      span {
        padding: 1rem;
        display: block;
      }
      &:nth-child(2) {
        text-align: center;
        flex: var(--second-column);
      }
      &:nth-child(3) {
        text-align: center;
        flex-direction: column;
        flex: var(--third-column);
      }
    }
    &__subtitle {
      border-top: 1px solid #000;
      display: flex;
      width: 100%;
      // margin-top: 1em;
      div {
        flex: 1;
        font-size: 0.8em;
        padding: 0.5em;
      }
    }
  }
  .table-bottom {
    display: flex;
    border-bottom: 1px solid #000;
    &:last-child {
      border-bottom: none;
    }
    &__title {
      border-right: 1px solid #000;
      flex: var(--first-column);
      display: flex;
      &:last-child {
        border-right: none;
      }
      span {
        padding: 1rem;
        display: block;
      }
      &:nth-child(2) {
        font-size: 0.815em;
        flex: var(--second-column);
      }
      &:nth-child(3) {
        font-size: 0.78em;

        text-align: center;
        // flex-direction: column;
        flex: var(--third-column);
      }
    }
    &__text {
      flex: 1;
      padding: 0.8em;
      div {
        line-height: 1.5;
      }
    }
  }
}
.region-subtitle {
  margin-top: 1em;
  font-size: 1.375em;
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 0.5em;
  }
  .left {
    flex: 0 0 40%;
  }
  .right {
    flex: 0 0 40%;
  }
}
.online-instruction {
  font-weight: 500;
  font-size: 1.375em;
  margin-bottom: 5em;
  .border-text {
    position: relative;
    padding: 2em;
    background: #f4f4f4;
    backdrop-filter: blur(13.6225px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid var(--primary-blue);
    border-radius: 10.2169px;
    margin: 2em 0;
    .triangle {
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.mobile-info {
  display: none;
}
@media screen and (max-width: 576px) {
  .small-link {
    font-size: 1em;
  }
  .online-instruction {
    .border-text {
      padding: 1em;
    }
  }
}
@media screen and (max-width: 576px) {
  .table-container {
    display: none;
  }
  .announce-content {
    .table {
      display: none;
    }
    .fill-application {
      display: block;
      .text-bottom {
        width: 100%;
        margin-top: 49px;
      }
      .prompt {
        display: none;
      }
    }
  }
  .mobile-info {
    display: block;
    border-radius: 36px;
    color: var(--secondary-font-color);
    padding: 2rem;
    margin-bottom: 2rem;
    &.purple-bg {
      background: linear-gradient(
        90deg,
        #9400d3 0%,
        #6f65eb 0.01%,
        #4b0082 100%
      );
    }
    &.green-bg {
      background: linear-gradient(222.64deg, #239685 30.05%, #125669 114.24%);
    }

    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 1.5;
    }
    .subtitle {
      font-size: 18px;
      margin-bottom: 1rem;
    }
  }
  .prompt-mobile {
    display: block;
    color: #fff;
    padding: 10px 16px;
    background: var(--primary-blue);
    backdrop-filter: blur(13.6225px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10.2169px;
  }
  .mobile-table {
    display: block;
    &__item {
      font-size: 1.5em;
      line-height: 1.5;
      color: #000;
      padding-top: 15px;
      padding-bottom: 30px;
      border-bottom: 1px solid #000;
      .descr {
        margin-bottom: 21px;
      }
      .title {
        font-weight: 600;
      }
    }
  }
  .region-subtitle {
    display: block;
    .left {
      margin-bottom: 44px;
    }
  }

  .gap {
    gap: 4vw;
  }
  .flex {
    display: flex;
    width: 100%;
    .topik {
      font-size: 2.9vw;
      font-weight: 500;
    }
  }
  .flex-1 {
    flex: 1;
    .category {
      font-size: 3vw;
      font-weight: 500;
    }
  }
}
</style>
