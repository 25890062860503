<template>
  <div class="news">
    <div class="container-news">
      <div v-html="data.description"></div>
    </div>
    <h3 class="slide-title primary-title">{{ $t("announcePage.other") }}</h3>
    <AnnounceSlider />
  </div>
</template>

<script>
import AnnounceSlider from "@/components/AnnounceSlider.vue";
export default {
  components: { AnnounceSlider },
  props: {
    data: {
      type: Object,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.slide-title {
  margin-bottom: 3.13rem;
}
.container-news {
  max-width: 46.5rem;
  margin: 0 auto;

  &:deep() p {
    line-height: 1.5;
  }
}
.img {
  padding-bottom: 52%;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1200px) {
  .container-news {
    width: 100%;
    &:deep() p {
      line-height: 1.5;
      font-size: 1.375em;
    }
  }
}
@media screen and (max-width: 992px) {
  .container-news {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .slide-title {
    margin-bottom: 1.25rem;
  }
}
</style>
