<template>
  <div class="slider">
    <div class="slider-track" ref="sliderTrack">
      <div
        class="slider-item"
        ref="sliderItem"
        v-for="item in articles"
        :key="item"
      >
        <Card :item="item" />
      </div>
    </div>
    <div class="slider-control">
      <div class="buttons">
        <button class="arrow-button" @click="changeIndex(currentIndex - 1)">
          <svg
            width="1.81rem"
            height="1.19rem"
            viewBox="0 0 29 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29 7.91667H6.17056L11.9383 2.2325L9.66667 0L0 9.5L9.66667 19L11.9383 16.7675L6.17056 11.0833H29V7.91667Z"
              :fill="currentIndex == 0 ? '#D0D4EA' : '#377DFF'"
            />
          </svg>
        </button>
        <button class="arrow-button" @click="changeIndex(currentIndex + 1)">
          <svg
            width="1.81rem"
            height="1.19rem"
            viewBox="0 0 29 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.92097e-07 11.0833L22.8294 11.0833L17.0617 16.7675L19.3333 19L29 9.5L19.3333 -8.45087e-07L17.0617 2.2325L22.8294 7.91667L9.68936e-07 7.91666L6.92097e-07 11.0833Z"
              :fill="
                currentIndex == articles.length - (isMobile ? 1 : 2)
                  ? '#D0D4EA'
                  : '#377DFF'
              "
            />
          </svg>
        </button>
      </div>
      <div class="indicators">
        <div
          class="indicators-item"
          :class="{ active: currentIndex == index }"
          v-for="(item, index) in articles"
          :key="item"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from "@/components/AnnounceCard.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const pageSize = 6;
const currentIndex = ref(0);
const sliderTrack = ref();
const sliderItem = ref();
const isMobile = ref(false);

const handleResize = () => {
  isMobile.value = window.innerWidth <= 575;
};

if (typeof window != undefined) {
  isMobile.value = window.innerWidth <= 575;
  window.addEventListener("resize", handleResize);
}

const changeIndex = (index) => {
  if (
    !(index < 0) &&
    !(articles.value.length - (isMobile.value ? 0 : 1) == index)
  ) {
    currentIndex.value = index;
    sliderTrack.value.style.transform = `translateX(-${
      sliderTrack.value.children[0].offsetWidth * currentIndex.value
    }px)`;
  }
};

const articles = computed(() => store.getters["articles/getArticlesList"]);

if (articles.value.length == 0) {
  store.dispatch("articles/getArticlesListPagination", {
    cb: () => {},
    pageSize,
    load: 1,
  });
}
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;

  &-track {
    display: flex;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &-item {
    flex: 1 0 calc(50%);
    padding-right: 1.25rem;
  }

  &-control {
    width: max-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.75rem;
    width: 100%;
    max-width: 23.5rem;

    .buttons {
      .arrow-button {
        background: none;
        cursor: pointer;

        &:first-child {
          margin-right: 2.5rem;
        }
      }
    }

    .indicators {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      &-item {
        width: 0.75rem;
        height: 0.75rem;
        background: var(--bg-light-grey);
        border-radius: 50%;

        &.active {
          background: var(--primary-blue);
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .slider {
    &-item {
      flex: 0 0 100%;
    }

    &-control {
      margin-top: 1.88rem;
    }
  }
}
</style>
